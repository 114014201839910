//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { flagSet } from '@coreui/icons'
export default {
  name: 'Flags',
  flagSet,
  computed: {
    displayedFlags () {
      return this.$options.flagSet
    }
  },
  methods: {
    toKebabCase (str) {
      return str.replace(/([a-z])([A-Z0-9])/g, '$1-$2').toLowerCase()
    }
  }
}
